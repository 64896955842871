import React from "react";
import classes from "./Info.module.css";

export const Info = () => {
  return (
    <div className={classes.InfoContainer}>
      <h2 className={classes.Header}>
        Auswahl der besten Online-Casino-Site: Ein vollständiger Leitfaden
      </h2>
      <div className={classes.InfoSection}>
        <h5>Vertrauenswürdig & Sicher:</h5>
        <p>
          Entdecken Sie unsere Auswahl an Online-Casinos, die Ihre Sicherheit an
          erste Stelle setzen. Unsere empfohlenen Plattformen sind lizenziert
          und reguliert, um die österreichischen Glücksspielstandards zu
          erfüllen. Mit fortschrittlicher Verschlüsselungstechnologie sind Ihre
          finanziellen Transaktionen sicher, und ein Bekenntnis zu fairem Spiel
          garantiert eine vertrauenswürdige Spielerumgebung.
        </p>
      </div>
      <div className={classes.InfoSection}>
        <h5>Spiele & Software:</h5>
        <p>
          Tauchen Sie ein in eine Welt des Spielegenusses mit unseren
          ausgewählten Casinos. In Partnerschaft mit führenden Softwareanbietern
          bieten sie eine vielfältige Auswahl an Spielen, von klassischen
          Tischspielen bis hin zu innovativen Videoslots. Genießen Sie eine
          nahtlose Erfahrung mit erstklassiger Grafik und realistischen
          Soundeffekten, alles optimiert für die Wiedergabe auf verschiedenen
          Geräten.
        </p>
      </div>
      <div className={classes.InfoSection}>
        <h5>Bonusangebote:</h5>
        <p>
          Maximieren Sie Ihr Spielerlebnis mit verlockenden Bonusangeboten.
          Unsere empfohlenen Casinos heißen Sie mit großzügigen Boni willkommen,
          darunter Willkommenspakete und laufende Aktionen. Schalten Sie
          zusätzliche Spielmacht frei und erhöhen Sie Ihre Gewinnchancen mit
          diesen exklusiven Anreizen.
        </p>
      </div>
      <div className={classes.InfoSection}>
        <h5>Umsatzbedingungen:</h5>
        <p>
          Verstehen Sie die Bedingungen, die Ihr Spielerlebnis verbessern.
          Umsatzbedingungen, ein wichtiger Aspekt von Boni, werden von unseren
          empfohlenen Casinos transparent kommuniziert. Wir bieten Klarheit über
          die Durchspielbedingungen, um sicherzustellen, dass Sie informierte
          Entscheidungen treffen, wenn Sie von Bonusangeboten profitieren.
        </p>
      </div>
      <div className={classes.InfoSection}>
        <h5>Bewertungen:</h5>
        <p>
          Lesen Sie vor dem Spielen Erkenntnisse aus unseren
          Expertenbewertungen. Wir analysieren die Stärken und Schwächen jedes
          Casinos und bewerten Faktoren wie Spielvielfalt, Kundensupport und
          Zahlungsmethoden. Unsere Bewertungen liefern wertvolle Informationen,
          um das ideale Online-Casino gemäß Ihren Vorlieben und Bedürfnissen
          auszuwählen. Vertrauen Sie unserer Expertise, um fundierte
          Spielentscheidungen in Österreich zu treffen.
        </p>
      </div>
    </div>
  );
};
