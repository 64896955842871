// FAQ.jsx

import React, { useState } from "react";
import classes from "./Faq.module.css";

export const FAQ = () => {
  const faqData = [
    {
      question:
        "Kann ich auf diesen Online-Casinos von meinem mobilen Gerät aus spielen?",
      answer:
        "    Absolut! Unsere empfohlenen Casinos sind für mobile Geräte optimiert. Egal, ob Sie lieber ein Smartphone oder Tablet verwenden, Sie können ein nahtloses Spielerlebnis unterwegs genießen. Überprüfen Sie die mobile Kompatibilität jedes Casinos, um sicherzustellen, dass Sie die Flexibilität haben, von Ihrem bevorzugten Gerät aus zu spielen.",
    },
    {
      question: "Wie kann ich Geld einzahlen und abheben?",
      answer:
        "    Das Einzahlen und Abheben von Geld ist einfach. Unsere empfohlenen Casinos bieten eine Vielzahl sicherer Zahlungsoptionen, darunter Kreditkarten, E-Wallets und Banküberweisungen. Erkunden Sie die verfügbaren Bankmethoden auf jeder Plattform, um die bequemsten und sichersten Optionen für Ihre Transaktionen zu finden.",
    },
    {
      question: "Ist der Kundensupport leicht erreichbar?",
      answer:
        "    Ja, unsere empfohlenen Casinos legen Wert auf Kundenzufriedenheit. Sie bieten einen reaktionsschnellen Kundensupport über verschiedene Kanäle wie Live-Chat, E-Mail und Telefon. Überprüfen Sie die Kundensupport-Optionen jedes Casinos, um sicherzustellen, dass Ihnen Hilfe jederzeit zur Verfügung steht.",
    },
    {
      question: "Gibt es Einschränkungen für Spieler in Österreich?",
      answer:
        "Es ist wichtig, sich über länderspezifische Einschränkungen im Klaren zu sein. Unsere empfohlenen Casinos heißen Spieler aus Österreich willkommen, aber es ist ratsam, die Geschäftsbedingungen jeder Plattform zu überprüfen, um die Einhaltung lokaler Vorschriften und eventueller spezifischer Beschränkungen sicherzustellen.",
    },
    {
      question: "Wie praktiziere ich verantwortungsbewusstes Spielen?",
      answer:
        " Verantwortungsbewusstes Spielen ist entscheidend. Unsere empfohlenen Casinos fördern eine sichere Spielerumgebung, indem sie Tools für verantwortungsbewusstes Glücksspiel anbieten, wie Einzahlungslimits, Selbstsperre und Realitätschecks. Machen Sie sich mit diesen Funktionen vertraut, um ein gesundes und angenehmes Spielerlebnis sicherzustellen.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleQuestionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={classes.faq}>
      <h2 className={classes.heading}>Häufig gestellte Fragen</h2>
      <div className={classes.ListWrapper}>
        <ul className={classes.list}>
          {faqData.map((item, index) => (
            <li
              key={index}
              onClick={() => handleQuestionClick(index)}
              className={`ListItem${
                activeIndex === index ? classes.active : ""
              }`}
            >
              <h3 className={classes.question}>{item.question}</h3>
              {activeIndex === index && (
                <p className={classes.answer}>{item.answer}</p>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
