// Header.js

import React, { useState } from 'react';
import classes from './Header.module.css';
import { Link } from 'react-router-dom';

export const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const toggleNavbar = () => {
    setShowNavbar((prev) => !prev);
  };

  return (
    <div className={classes.header}>
      <div className={classes.logo}>
        <Link to="/">
          <img src="./Images/logo.png" alt="Logo" />
        </Link>
      </div>
      <div className={`${classes.navbar} ${showNavbar ? classes.show : ''}`}>
        <Link to="/Slots" className={classes.NavLink}>
          Spielautomaten
        </Link>
        <Link to="/CasinoGames" className={classes.NavLink}>
          Casinospiele
        </Link>
        <Link to="/PaymentMethods" className={classes.NavLink}>
          Zahlungsarten
        </Link>
        <Link to="/ContactUs" className={classes.NavLink}>
          Kontaktiere uns
        </Link>
      </div>
      <div className={classes.menuIcon} onClick={toggleNavbar}>
        ☰
      </div>
    </div>
  );
};
