import React, { useState } from "react";
import classes from "./CasinoList.module.css";
import Cookies from "js-cookie";

export const CasinoList = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const sidFromQuery = new URLSearchParams(window.location.search).get("sid");
  const fromCookie = Cookies.get("sid");
  let sid = "";
  if (sidFromQuery !== null && sidFromQuery !== undefined) {
    sid = sidFromQuery;
  } else if (fromCookie !== null && fromCookie !== undefined) {
    sid = fromCookie;
  }
  const Casinos = [
    {
      logo: "./Images/Pribet.png",
      text: "BIS ZU 1000€ + 150 Freispiele",
      grade: "10 ★",
      deposit: "10€ min. einzahlung",
      link: `https://online.pribet.com/promoRedirect?key=ej0xNDc5MzYxOSZsPTAmcD00MDY4Ng%3D%3D&var9=${sid}`,
    },
    {
      logo: "./Images/Zodiacbet.png",
      text: "bis zu 1000€ + 100 Freispiele",
      grade: "9.8 ★",
      deposit: "10€ min. einzahlung",
      link: `https://online.zodiacbet.com/promoRedirect?key=ej0xMzUyODM5MiZsPTAmcD00MDY4Ng%3D%3D&var9=${sid}`,
    },
    {
      logo: "./Images/Dachbet.png",
      text: "BIS ZU 1000€ + 150 Freispiele",
      grade: "9.7 ★",
      deposit: "10€ min. einzahlung",
      link: `https://online.dachbet.com/promoRedirect?key=ej0xMzUyNDI1MSZsPTEzNTI0MjAxJnA9MTk1ODk%3D&var9=${sid}`,
    },
    {
      logo: "./Images/Emirbet.png",
      text: "BIS ZU 1000€ + 150 Freispiele",
      grade: "9.7 ★",
      deposit: "10€ min. einzahlung",
      link: `https://online.emirbet.com/promoRedirect?key=ej0xMzUyNDg2OSZsPTAmcD01NTIx&var9=${sid}`,
    },
    {
      logo: "./Images/olympusbet.png",
      text: "100% BIS ZU 500€ + 100 Freispiele",
      grade: "9.6 ★",
      deposit: "10€ min. einzahlung",
      link: `https://online.olympusbet.com/promoRedirect?key=ej0xMzgzMDk3MyZsPTAmcD0xOTU4OQ%3D%3D&var9=${sid}`,
    },
  ];

  return (
    <div className={classes.casinoList}>
      <div className={classes.IntroContainer}>
        <h1 className={classes.MainHeader}>
          Beste Online-Casinos für österreichische Spieler – 2024
        </h1>
        <p className={classes.IntroText}>
          Finden Sie die besten Casino- und Slotseiten in Österreich. Jede Marke
          wurde anhand ihrer bemerkenswertesten Merkmale bewertet und bewertet.
          <br />
          Vergleichen Sie sie unten, um das Richtige für Sie zu finden. Von
          spannenden Spielen bis hin zu verlockenden Werbeaktionen sind wir
          hier, um Ihnen dabei zu helfen, die richtige Wahl zu treffen.
        </p>
      </div>
      {Casinos.map((casino, index) => (
        <div
          key={index}
          className={classes.itemContainer}
          onMouseEnter={() => setHoveredItem(index)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <div className={classes.LogoContainer}>
            <img
              src={casino.logo}
              alt="Casino Logo"
              onClick={() => (window.location.href = casino.link)}
            />
          </div>
          <div className={classes.TextContainer}>
            <span className={classes.TextSpan}>{casino.text}</span>
          </div>
          <div
            className={`${
              hoveredItem === index ? classes.pulseAnimation : ""
            } ${classes.GradeContainer}`}
          >
            <span className={classes.GradeSpan}>{casino.grade}</span>
          </div>
          <div className={classes.DepositContainer}>
            <button
              className={classes.casinoButton}
              onClick={() => (window.location.href = casino.link)}
            >
              JETZT SPIELEN
            </button>
            <span className={classes.DepositSpan}>{casino.deposit}</span>
          </div>
        </div>
      ))}
      <div className={classes.bottom}>
        <span>Tolle Willkommensboni für Neukunden.</span>
        <span>
          {" "}
          Einfache Anmeldung, schnelle Auszahlungen und sichere Websites.
        </span>
        <span>Casinoseiten unabhängig überprüft und genehmigt.</span>
      </div>
    </div>
  );
};
